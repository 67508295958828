<!--客服部费用池-->
<template>
  <div>
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="司机姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="车牌号" clearable></el-input>
        </el-form-item>
        <el-form-item label="对接人" prop="careIdList">
          <el-select class="item-choose"
                     v-model="dataForm.careIdList"
                     size="small"
                     filterable clearable placeholder="请选择"
          >
            <el-option
              v-for="item in userJobMap.CARE"
              :key="item.id"
              :label="item.realName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select class="item-choose" v-model="dataForm.status" style="width: 100%;" filterable clearable placeholder="请选择">
            <el-option label="已审核" value="1"></el-option>
            <el-option label="未审核" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款状态" prop="writeStatus">
          <el-select class="item-choose" v-model="dataForm.writeStatus" style="width: 100%;" filterable clearable placeholder="请选择">
            <el-option label="已付款" value="1"></el-option>
            <el-option label="未付款" value="2"></el-option>
            <el-option label="部分付款" value="3"></el-option>
            <el-option label="申请中" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" v-if="newAuth('cost:base:export')" @click="downLoad">导出</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="getLatestData()" type="primary">获取最新数据</el-button>
          <el-button size="small" type="success" v-if="newAuth('cost:base:update')" @click="openWriteOffPopup(1)" :disabled="!selectFeeData.length">已付款核销</el-button>
          <el-button size="small" type="success" v-if="newAuth('cost:base:update')" @click="batchAudit(2)" :disabled="!selectFeeData.length">反核销</el-button>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList">
            <el-button size="small" @click="operatopnList('pass','', '',1)"
                       type="primary"
                       v-if="newAuth('customer:service:department:auditStatus1')"
                       :disabled="selectFeeData.length ? false : true">费用审核通过</el-button>
            <el-button size="small" @click="operatopnList('pass','', '',2)"
                       type="primary"
                       v-if="newAuth('customer:service:department:auditStatus2')"
                       :disabled="selectFeeData.length ? false : true">反审核</el-button>
            <el-button size="small" type="success" v-if="newAuth('request:order:add')" @click="createPayRequest"
                       :disabled="!selectFeeData.length">
              生成付款申请单
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox customer-service-department-table">
      <el-table :data="dataList" border size="small" :height="tableHeight" ref="table"
                :span-method="arraySpanMethod"
                :summary-method="getSummaries"
                show-summary
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                v-loading="dataListLoading" style="width: 100%;"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="35" fixed="left" align="center"></el-table-column>
        <el-table-column label="提货时间" width="105" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="scope">
            {{scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
          </template>
        </el-table-column>
        <el-table-column label="客户名称" prop="orderInfo.customerName" width="70" :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" width="80" :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column label="司机车牌"  prop="vehiclePlan.vehiclePlate" width="70" :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column label="司机姓名"  prop="vehiclePlan.driverName" width="60"  :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column label="订单类型" :formatter="orderType" prop="orderInfo.orderType" width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单车型" :formatter="dictConvertVehicle" width="80" :show-overflow-tooltip="true"  prop="orderInfo.vehicleType">
        </el-table-column>
<!--        <el-table-column label="指派车型" prop="vehiclePlan.vehicleTypeName" width="80" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
        <el-table-column label="提货地址" prop="route" width="100" :show-overflow-tooltip="true">
        </el-table-column>
<!--        <el-table-column label="提货地址" prop="fullAddress" width="180" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
        <el-table-column label="报关方式" :formatter="entranceType" prop="orderInfo.entranceType" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货地址" prop="giveAddressInfo.route" width="100" :show-overflow-tooltip="true">
        </el-table-column>
<!--        <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" width="180" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column label="司机电话"  prop="vehiclePlan.driverPhone" width="90"  :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
        <el-table-column label="应收运费" prop="officeCostCare.freightFee" :formatter="getNumValue"  width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="加点费" prop="officeCostCare.addFee" :formatter="getNumValue"  width="50"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="押夜/超时费" prop="officeCostCare.overtimeFee" :formatter="getNumValue"  width="75"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提成费" prop="officeCostCare.careRoyaltyFee" :formatter="getNumValue" width="50"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="装卸费" prop="officeCostCare.loadFee" :formatter="getNumValue" width="50"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="加班费" prop="officeCostCare.extraFee" :formatter="getNumValue" width="50"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="住宿费" prop="officeCostCare.stayFee" :formatter="getNumValue" width="50"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="基本工资" prop="officeCostCare.careBaseFee" :formatter="getNumValue" width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="绩效奖罚金" prop="officeCostCare.meritsFee" :formatter="getNumValue" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="APP奖罚金" prop="officeCostCare.appFee" :formatter="getNumValue" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="租房费" prop="officeCostCare.other1Fee" :formatter="getNumValue" width="50" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="油耗奖励" prop="officeCostCare.other2Fee" :formatter="getNumValue" width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="安全奖罚金" prop="officeCostCare.safetyRewards" :formatter="getNumValue" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="费用小计" prop="officeCostCare.payableAmount" :formatter="getNumValue" width="60" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column label="已付小计" prop="officeCostCare.paidAmount" :formatter="getNumValue" width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="未付小计" prop="officeCostCare.notPayAmount" :formatter="getNumValue" width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="扣社保/公积金" prop="officeCostCare.deductionsFee" :formatter="getNumValue"  width="90"  :show-overflow-tooltip="true" class-name="blue-cell">
        </el-table-column>
        <el-table-column label="请假扣款" prop="officeCostCare.accumulationFund" :formatter="getNumValue"  width="60"  :show-overflow-tooltip="true" class-name="blue-cell">
        </el-table-column>
        <el-table-column label="扣减安全奖" prop="officeCostCare.safetyAward" :formatter="getNumValue"  width="70"  :show-overflow-tooltip="true" class-name="blue-cell">
        </el-table-column>
        <el-table-column label="扣减违章/油耗" prop="officeCostCare.oilConsumption" :formatter="getNumValue"  width="90"  :show-overflow-tooltip="true" class-name="blue-cell">
        </el-table-column>
        <el-table-column label="分摊水电费" prop="officeCostCare.hydropower" :formatter="getNumValue"  width="70"  :show-overflow-tooltip="true" class-name="blue-cell">
        </el-table-column>
        <el-table-column label="个税" prop="officeCostCare.personalIncomeTax" :formatter="getNumValue"  width="50"  :show-overflow-tooltip="true" class-name="blue-cell">
        </el-table-column>
        <el-table-column label="其他费用" prop="officeCostCare.otherExpenses" :formatter="getNumValue"  width="60"  :show-overflow-tooltip="true" class-name="blue-cell">
        </el-table-column>
        <el-table-column label="实发小计" prop="officeCostCare.netSalary" :formatter="getNumValue"  width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="借支" prop="officeCostCare.borrowing" :formatter="getNumValue"  width="60"  :show-overflow-tooltip="true" class-name="yellow-cell">
        </el-table-column>
        <el-table-column label="备注" prop="remark" width="100"  :show-overflow-tooltip="true" class-name="yellow-cell">
          <template slot-scope="scope">
            {{ scope.row.officeCostCare.remark }}
          </template>
        </el-table-column>
        <el-table-column label="对接人" prop="officeCostCare.careName" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="status" label="订单状态" width="70" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">未提交</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">待审核</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">调度中</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small">已调度</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="success">已完成</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">异常结束</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">审核拒绝</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">订单退回</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="info">已取消</el-tag>
            <el-tag v-else-if="scope.row.status == 4" size="small">待出车</el-tag>
            <el-tag v-else-if="scope.row.status == 5" size="small" type="success">已出车</el-tag>
            <el-tag v-else-if="scope.row.status == 6" size="small" type="success">已到达</el-tag>
            <el-tag v-else-if="scope.row.status == 7" size="small" type="success">已装货</el-tag>
            <el-tag v-else-if="scope.row.status == 15" size="small" type="success">卸货到场</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="件数" prop="orderGoods.pieceNum" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="体积" prop="orderGoods.volume" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="重量" prop="orderGoods.weight" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="下单时间" prop="orderInfo.commitTime" width="120" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="下单人" prop="orderInfo.commitName" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="付款人" prop="officeCostCare.writeName" width="50" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="付款时间" prop="officeCostCare.writeDate" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="审核状态" fixed="right" width="60">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.officeCostCare.status == 1" size="small" type="success">已审核</el-tag>
            <el-tag v-else size="small" type="danger">未审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="付款状态" fixed="right" width="60">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.officeCostCare.writeStatus == 1" size="small" type="success">已付款</el-tag>
            <el-tag v-if="scope.row.officeCostCare.writeStatus == 2" size="small" type="danger">未付款</el-tag>
            <el-tag v-if="scope.row.officeCostCare.writeStatus == 3" size="small" type="info">部分付款</el-tag>
            <el-tag v-if="scope.row.officeCostCare.writeStatus == 4" size="small" type="warning">申请中</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('cost:base:find')" type="text" size="small" @click="operatopnList('cost', 'view', scope.row)">详情</el-button>
              <!-- 未付款的情况下可以修改数据 -->
              <el-button v-if="newAuth('cost:base:update')"
                         type="text" size="small" @click="operatopnList('cost', 'edit', scope.row)">费用修改
              </el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗 -->
    <cost-modification ref="cost" @refreshDataList="getDataList"></cost-modification>
    <view-image ref="viewPhoto"></view-image>
    <!-- 核销弹框 -->
    <write-off-popup ref="writeOff" @refreshDataList="getDataList"></write-off-popup>
    <!-- 付款申请单   -->
    <pay-request v-if="requestVisible" ref="payRequest" @refreshDataList="getDataList"></pay-request>
  </div>
</template>

<script>
import costModification from './detail/care-costModification-popup'
import viewImage from '@/views/commonPop/viewImage'
import writeOffPopup from './detail/write-royalty-popup'
import PayRequest from './detail/pay-request-popup'
export default {
  data () {
    return {
      dataForm: {
        takeGoodsTime: null,
        planNo: null,
        orderNo: null,
        status: null,
        inWarehouseNo: null,
        customerName: null,
        driverName: null,
        writeStatus: null,
        vehiclePlate: null,
        careDepIdList: [], // 客服部
        careIdList: [] // 客服员
      },
      tableHeight: '71vh',
      listType: 8,
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      pageSizes: [20, 50, 100],
      dataListLoading: false,
      requestVisible: false,
      dataListSelections: [],
      costImageList: [7001, 7002, 7003, 7004, 7005],
      addOrUpdateVisible: false,
      detailVisible: false,
      selectFeeData: [],
      userJobMap: new Map()
    }
  },
  components: {
    costModification,
    viewImage,
    writeOffPopup,
    PayRequest
  },
  created () {
    this.setDefaultDate()
    this.getDataList()
    // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
    window.setTimeout(() => {
      this.tableHeight = '72vh'
    }, 1000)
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs['table'].doLayout()
    })
  },
  methods: {
    // 获取对接人信息
    getUserList () {
      this.userJobMap = new Map()
      this.$http({
        url: this.$http.adornUrl(`/costInfo/findAllAByJobCodes`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.userJobMap = data
      })
    },
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100) !important'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    examine () {

    },
    handleSelectionChange (row) {
      this.selectFeeData = row
    },
    // 查看照片
    viewPhoto (row) {
      this.$refs.viewPhoto.init(row.officeCostCare.id, this.costImageList)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let preId = ''
        const values = data.map(item => {
          if (item.orderInfo.id === preId) {
            return ''
          }
          preId = item.orderInfo.id
          if (column.property && column.property.indexOf('officeCostCare.') > -1) {
            let val = column.property.split('.')
            return this.getValue(Number(item[val[0]][val[1]]))
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 截取当前数据到小数点后两位
    numFilter (value) {
      if (value === '') {
        return value
      }
      return parseFloat(parseFloat(value).toFixed(2))
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    getAllSummaries (officeCareRoyalty) {
      for (let c in officeCareRoyalty) {
        officeCareRoyalty[c] = this.getValue(officeCareRoyalty[c])
      }
      // 合计参数，按顺序返回
      let param = ['合计', '', '', '', '', '', '', '', '', '', '',
        officeCareRoyalty.freightFee,
        officeCareRoyalty.addFee,
        officeCareRoyalty.overtimeFee,
        officeCareRoyalty.careRoyaltyFee,
        officeCareRoyalty.loadFee,
        officeCareRoyalty.extraFee,
        officeCareRoyalty.stayFee,
        officeCareRoyalty.careBaseFee,
        officeCareRoyalty.meritsFee,
        officeCareRoyalty.appFee,
        officeCareRoyalty.other1Fee,
        officeCareRoyalty.other2Fee,
        officeCareRoyalty.safetyRewards,
        officeCareRoyalty.payableAmount,
        officeCareRoyalty.paidAmount,
        officeCareRoyalty.notPayAmount,
        officeCareRoyalty.deductionsFee,
        officeCareRoyalty.accumulationFund,
        officeCareRoyalty.safetyAward,
        officeCareRoyalty.oilConsumption,
        officeCareRoyalty.hydropower,
        officeCareRoyalty.personalIncomeTax,
        officeCareRoyalty.otherExpenses,
        officeCareRoyalty.netSalary,
        officeCareRoyalty.borrowing,
        '', '', '', '', '', '', '', '', '', '', '']
      // 创建列表行
      let tr = document.createElement('tr')
      // var itemTr = document.createElement('tr')
      // itemTr.innerHTML = document.querySelector('.el-table__footer-wrapper table tbody tr').innerHTML
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      if (document.querySelector('.el-table__fixed-footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__fixed-footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    createPayRequest () {
      let ids = []
      let motorcadeIdSet = new Set()
      let driverIdSet = new Set()
      for (let i = 0; i < this.selectFeeData.length; i++) {
        let item = this.selectFeeData[i]
        if (item.officeCostCare.writeStatus !== 1 && item.officeCostCare.status === 1) {
          // 校验是否同一个车队
          ids.push(item.officeCostCare.id)
          motorcadeIdSet.add(item.driverInfo.motorcadeId)
          driverIdSet.add(item.driverInfo.id)
        }
      }
      if (ids.length === 0) {
        this.$message.error('没有符合生成的数据，请检查是否勾选正确')
        return
      }
      // 校验是否同一个司机
      if (driverIdSet.size > 1) {
        this.$message.error('已选的订单存在多个付款司机对象，无法生成付款申请单')
        return
      }
      let costData = {
        ids: ids,
        motorcadeId: this.getSetFirst(motorcadeIdSet),
        payObject: 4, // 客服部
        payBasisSource: 1,
        payOrderType: 3,
        disableDriver: true,
        driverId: this.getSetFirst(driverIdSet)
      }
      // 确认对话框
      this.$confirm('请确认是否生成付款申请单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.openPayRequest(costData)
      }).catch(() => {
      })
    },
    getSetFirst (set) {
      let arr = [...set]
      return arr[0]
    },
    openPayRequest (costData) {
      this.requestVisible = true
      this.$nextTick(() => {
        this.$refs.payRequest.payGenInit(costData)
      })
    },
    operatopnList (type, todo, row, status) {
      if (type === 'pass') {
        // this.$refs.pass.init()
        let ids = []
        if (row) {
          ids.push(row.officeCostCare.id)
        } else {
          this.selectFeeData.forEach(item => {
            ids.push(item.officeCostCare.id)
          })
        }
        this.$confirm(status === 1 ? '正在执行【费用审核通过】操作，是否继续?' : '正在执行【反审核】操作，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/officeCost/officeCareRoyalty/audit'),
            method: 'post',
            data: {
              ids: ids,
              status: status
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => {})
      } else if (type === 'cost') {
        this.$refs.cost.init(type, todo, row)
      }
    },
    batchAudit (writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.officeCostCare.writeStatus !== writeStatus) {
          ids.push(item.officeCostCare.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/officeCost/careOrderRoyalty/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    openWriteOffPopup (writeStatus) {
      this.writeOffVisible = true
      let ids = []
      this.selectFeeData.forEach(item => {
        // 已付款和申请中的不可核销
        if (item.officeCostCare.writeStatus !== writeStatus && item.officeCostCare.writeStatus !== 4) {
          ids.push(item.officeCostCare.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      this.$refs.writeOff.init(writeStatus, ids, 'care')
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/export/careExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'listType': this.listType,
          'page': this.pageIndex,
          'limit': this.pageSize,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'status': this.dataForm.status,
          'writeStatus': this.dataForm.writeStatus,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'careId': this.dataForm.careIdList // 传入业务对接人
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '客服部提成费用表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 计算最新数据：基本工资、绩效奖励、使用APP奖励
    getLatestData () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/officeCost/getLatestDataCare`),
        method: 'POST',
        data: this.$http.adornData({
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'careId': this.dataForm.careIdList
        })
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.getDataList()
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.getUserList()
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/officeCost/findOfficeCostCareList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'status': this.dataForm.status,
          'writeStatus': this.dataForm.writeStatus,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'careId': this.dataForm.careIdList, // 传入客服员对接人
          'driverName': this.dataForm.driverName,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'customerName': this.dataForm.customerName
        })
      }).then(({ data }) => {
        if (data) {
          this.getSpanArr(data.list, 1)
          this.dataList = data.list
          this.totalPage = data.total
          if (this.totalPage > 100) {
            this.pageSizes = [20, 50, 100, this.totalPage]
          } else {
            this.pageSizes = [20, 50, 100]
          }
          this.getAllSummaries(data.officeCareRoyalty || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (row, todo) {
      this.$nextTick(() => {
        this.$refs.detail.init(row, todo)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.simpleName + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/company/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less">
.customer-service-department-table {
  .el-table{
    overflow:visible !important;
    .el-table__fixed-right {
      background: #f5f7fa;
    }
  }
  .el-table__body-wrapper{
    z-index: 2;
  }
  .cell.el-tooltip {
    min-width: 0;
    padding: 0 !important;
  }
  .hover-row,.selected-row {
    td {
      background: rgb(250, 195, 100) !important;
    }
    .el-input__inner {
      background: rgb(250, 195, 100);
    }
  }
  .el-input__inner {
    padding: 0;
    border: 0;
    border-radius: 0;
  }
  .el-input__inner:hover {
    background: #fff
  }
}
</style>
